import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"

const Posts = ({ allBlogs }) => {
  return (
    <>
      <Container className="!pt-5">
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 md:gap-8 lg:gap-10">
          {allBlogs.map(({ data, uid }, index) => {
            const coverImage = getImage(data.image)
            return (
              <div key={index} className="overflow-hidden rounded-lg shadow-lg">
                <Link to={`/article/${uid}`}>
                  <GatsbyImage
                    image={coverImage}
                    alt={data.blog_title.text}
                    className="h-[220px] md:h-[270px] lg:h-[220px] object-cover "
                  />
                </Link>
                <div className="p-6">
                  <div className="mb-2">
                    <h2 className="">
                      <Link
                        to={`/article/${uid}`}
                        className="font-bold tracking-wide transition-all md:tracking-wider sm:text-lg text-heading hover:text-primary"
                      >
                        {data.blog_title.text}
                      </Link>
                    </h2>
                  </div>
                  <div>
                    <div className="text-xs">{data.date}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </>
  )
}

export default Posts
