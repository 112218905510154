import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"

import Hero from "../sections/blog/Hero/Hero"

import Posts from "../sections/blog/Posts/Posts"
import FancyNav from "../sections/common/FancyNav/FancyNav"
import Content from "../sections/common/Content/Content"
import Reviews from "../sections/common/Reviews/Reviews"

const BlogPage = ({ data }) => {
  if (!data) return null

  const allBlogs = data.allPrismicBlog.nodes
  const pageData = data.prismicBlogListing.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />
      <Helmet>
        <meta name="robots" content="index,nofollow" />
        <meta name="googlebot" content="index,nofollow" />
      </Helmet>

      <section>
        <Hero />
      </section>
      <section>
        <Posts allBlogs={allBlogs} />
      </section>

      <section className="py-4 md:py-6 lg:py-8">
        <FancyNav />
      </section>

      <section>
        <Content />
      </section>
      <section className="pb-5 md:pb-7 lg:pb-10">
        <Reviews />
      </section>
    </>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allPrismicBlog(sort: { order: DESC, fields: data___date }) {
      nodes {
        data {
          blog_title {
            text
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          date(formatString: "MMMM DD, YYYY")
        }
        uid
      }
    }

    prismicBlogListing {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
