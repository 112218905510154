import React from "react"
import { Link } from "gatsby"
import Container from "../../../components/Container/Container"
import { Dots } from "../../../components/Icons"

const menuItems = [
  { title: "Rooms", link: "rooms", isExternal: false },
  { title: "Gallery", link: "gallery", isExternal: false },
  { title: "Contact", link: "contact", isExternal: false },
  {
    title: "Book Now",
    link: "booking",
    isExternal: false,
  },
]

const FancyNav = () => {
  return (
    <Container className="!py-0">
      <div className="flex justify-center">
        <nav className="px-6 py-4 bg-white border">
          <ul className="grid sm:grid-cols-2 lg:grid-cols-4">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className="text-xs md:text-sm tracking-[3px] relative group flex items-center"
              >
                {item.isExternal ? (
                  <a
                    href={`${item.link}`}
                    className="block px-6 py-4 font-bold leading-5 text-center uppercase transition text-secondary hover:text-heading md:font-normal"
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to={`/${item.link}`}
                    className="block px-6 py-4 font-bold leading-5 text-center uppercase transition text-secondary hover:text-heading md:font-normal"
                  >
                    {item.title}
                  </Link>
                )}
                {menuItems.length > index + 1 && (
                  <span className="text-secondary">
                    <Dots />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Container>
  )
}

export default FancyNav
