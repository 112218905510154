import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"

const Content = () => {
  const { prismicCommon } = useStaticQuery(graphql`
    query {
      prismicCommon {
        data {
          content_name {
            text
          }
          content_title {
            text
          }
          content_text {
            html
          }
          content_image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const homeData = prismicCommon.data
  const contentImage = getImage(homeData.content_image)

  return (
    <>
      <Container>
        <div className="flex flex-col md:flex-row">
          <div className="flex-auto w-full md:w-5/12">
            <GatsbyImage
              image={contentImage}
              alt={homeData.content_name.text}
            />
          </div>
          <div className="flex-auto w-full md:w-7/12 md:pl-9 lg:pl-20 lg:pr-10">
            <div className="flex md:pt-[20px] pt-8">
              <div className="flex-auto w-3/4 md:max-w-[300px] xl:max-w-none">
                <Title preTitle>{homeData.content_name.text}</Title>
                <Title>{homeData.content_title.text}</Title>
              </div>
              <div className="items-center flex-auto hidden w-1/4 md:flex">
                <StaticImage
                  src="../../../assets/images/and-bg-symbol.png"
                  alt="And bg"
                  placeholder="none"
                  className="md:mt-[-30px] lg:mt-[-65px]"
                />
              </div>
            </div>

            <div className="pt-3 md:pt-5">
              <div
                dangerouslySetInnerHTML={{ __html: homeData.content_text.html }}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Content
